import React from "react";
import s from "./Model.module.css";
import { register } from "swiper/element/bundle";
register();

const Model = (props) => {
  let style;
  if (props.new_index === props.index) {
    style = {
      border: "3px solid gold",
      width: "144px",
      height: "144px",
    };
  }
  let chengeModel = () => {
    props.chengeModel(
      props.model_name,
      props.index,
      props.details_block,
      props.size_block,
      props.dimension,
    );
  };
  return (
    <swiper-slide>
      <button className={s.model_wrapper} onClick={chengeModel}>
        <div className={s.name}>{props.model_name}</div>
        <img className={s.image_model} src={props.image} style={style} alt="" />
      </button>
    </swiper-slide>
  );
};

export default Model;
