import styles from './NotFound.module.css';

function NotFound() {
  return (
    <div className={styles.wrapper}>
      <h1>Страница не найдена:(</h1>
    </div>
  );
}
export default NotFound;
