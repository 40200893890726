import React from "react";
import s from "./Castom.module.css";
import Model from "./Model/Model";
import Color from "./Color/Color";
import ColorSecond from "./ColorSecond/ColorSecond";
import Furniture from "./Furniture/Furniture";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Size from "./Size/Size";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css/navigation";
import { BiBorderBottom } from "react-icons/bi";

const Castom = (props) => {
	let model = props.castom.final.model_name;
	let color = props.castom.final.main_color;
	let color_details = props.castom.final.detail_color;
	let size_batty = props.castom.final.size;
	let furniture_color = props.castom.final.furniture;
	let link;
	if (
		props.castom.final.model_name === "SARAH" ||
		props.castom.final.model_name === "ROSE" ||
		props.castom.final.model_name === "РЮКЗАК" ||
		props.castom.final.model_name === "RITA"
	) {
		link =
			"https://wa.me/79247039139?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%97%D0%B0%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D0%BE%D0%B2%D0%B0%D0%BB%D0%B0%20%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D1%8C%20-%20" +
			"%20" +
			model +
			"%20%D0%B2%20%D1%86%D0%B2%D0%B5%D1%82%D0%B5%20" +
			color +
			".%20%D0%A6%D0%B2%D0%B5%D1%82%20%D1%84%D1%83%D1%80%D0%BD%D0%B8%D1%82%D1%83%D1%80%D1%8B%20-%20" +
			furniture_color +
			".%20%D0%9A%D0%B0%D0%BA%20%D0%BE%D1%84%D0%BE%D1%80%D0%BC%D0%B8%D1%82%D1%8C%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%3F";
	} else if (props.castom.final.model_name === "OLIVIA") {
		link =
			"https://wa.me/79247039139?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%97%D0%B0%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D0%BE%D0%B2%D0%B0%D0%BB%D0%B0%20%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D1%8C%20-%20" +
			"%20" +
			model +
			"%20%D0%B2%20%D1%86%D0%B2%D0%B5%D1%82%D0%B5%20" +
			color +
			".%D0%A6%D0%B2%D0%B5%D1%82%20%D0%B4%D0%B5%D1%82%D0%B0%D0%BB%D0%B5%D0%B9%20-%20" +
			color_details +
			".%20%D0%A6%D0%B2%D0%B5%D1%82%20%D1%84%D1%83%D1%80%D0%BD%D0%B8%D1%82%D1%83%D1%80%D1%8B%20-%20" +
			furniture_color +
			".%20%D0%9A%D0%B0%D0%BA%20%D0%BE%D1%84%D0%BE%D1%80%D0%BC%D0%B8%D1%82%D1%8C%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%3F";
	} else if (props.castom.final.model_name === "BATTY") {
		link =
			"https://wa.me/79247039139?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%97%D0%B0%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D0%BE%D0%B2%D0%B0%D0%BB%D0%B0%20%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D1%8C%20-%20" +
			"%20" +
			model +
			"%20%D0%B2%20%D1%86%D0%B2%D0%B5%D1%82%D0%B5%20" +
			color +
			".%20%D0%A0%D0%B0%D0%B7%D0%BC%D0%B5%D1%80%20-%20" +
			size_batty +
			".%20%D0%A6%D0%B2%D0%B5%D1%82%20%D1%84%D1%83%D1%80%D0%BD%D0%B8%D1%82%D1%83%D1%80%D1%8B%20-%20" +
			furniture_color +
			".%20%D0%9A%D0%B0%D0%BA%20%D0%BE%D1%84%D0%BE%D1%80%D0%BC%D0%B8%D1%82%D1%8C%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%3F";
	} else if (props.castom.final.model_name === "MIA") {
		link =
			"https://wa.me/79247039139?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%97%D0%B0%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D0%BE%D0%B2%D0%B0%D0%BB%D0%B0%20%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D1%8C%20-%20" +
			"%20" +
			model +
			"%20%D0%B2%20%D1%86%D0%B2%D0%B5%D1%82%D0%B5%20" +
			color +
			".%20%D0%9A%D0%B0%D0%BA%20%D0%BE%D1%84%D0%BE%D1%80%D0%BC%D0%B8%D1%82%D1%8C%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%3F";
	}

	let style_detail;
	if (
		props.castom.final.model_name === "SARAH" ||
		props.castom.final.model_name === "BATTY" ||
		props.castom.final.model_name === "MIA" ||
		props.castom.final.model_name === "ROSE" ||
		props.castom.final.model_name === "РЮКЗАК" ||
		props.castom.final.model_name === "RITA"
	) {
		style_detail = {
			display: "none",
		};
	} else {
		style_detail = {
			display: "flex",
		};
	}
	let style_furniture;
	let style_furniture_block;
	if (props.castom.final.model_name === "MIA") {
		style_furniture = {
			display: "none",
		};
		style_furniture_block = {
			display: "none",
		};
	} else {
		style_furniture = {
			display: "flex",
		};
		style_furniture_block = {
			display: "block",
		};
	}
	let style_size_form;
	if (props.castom.final.model_name === "BATTY") {
		style_size_form = {
			display: "flex",
		};
	} else {
		style_size_form = {
			display: "none",
		};
	}
	let info_style;
	if (props.castom.final.model_name === "RITA") {
		info_style = {
			display: "flex",
		};
	} else {
		info_style = {
			display: "none",
		};
	}
	const checkForm = () => {
		if (props.castom.check_form) {
			props.checkForm(false);
		} else {
			props.checkForm(true);
		}
	};
	const makeSale = () => {
		props.checkForm(true);
	};
	let style;
	if (props.castom.check_form) {
		style = {
			display: "flex",
		};
	} else {
		style = {
			display: "none",
		};
	}
	let models = props.castom.models.map((p) => (
		<SwiperSlide>
			<Model
				image={p.model}
				model_name={p.name}
				details_block={p.details_block}
				size_block={p.size_block}
				chengeModel={props.chengeModel}
				index={props.castom.models.indexOf(p)}
				new_index={props.castom.final.index_model}
				dimension={p.dimension}
			/>
		</SwiperSlide>
	));

	let colors = props.castom.colors.map((p) => (
		<SwiperSlide>
			<Color
				image={p.color}
				name={p.name}
				changeColor={props.changeColor}
				index={props.castom.colors.indexOf(p)}
				new_index={props.castom.final.index_color}
			/>
		</SwiperSlide>
	));

	let colors_second = props.castom.colors.map((p) => (
		<SwiperSlide>
			<ColorSecond
				image={p.color}
				name={p.name}
				index={props.castom.colors.indexOf(p)}
				new_index={props.castom.final.index_color_detail}
				changeColorDetail={props.changeColorDetail}
			/>
		</SwiperSlide>
	));

	let furniture = props.castom.furniture.map((p) => (
		<SwiperSlide>
			<Furniture
				image={p.model}
				name={p.name}
				changeFurniture={props.changeFurniture}
				index={props.castom.furniture.indexOf(p)}
				new_index={props.castom.final.index_furniture}
			/>
		</SwiperSlide>
	));
	let size = props.castom.size.map((p) => (
		<Size
			name={p.name}
			index={props.castom.size.indexOf(p)}
			new_index={props.castom.final.index_size}
			changeSize={props.changeSize}
		/>
	));
	let style_second_color = {
		display: props.castom.final.details_block,
	};
	let style_size = {
		display: props.castom.final.size_block,
	};
	ScrollToTop();
	return (
		<div className={s.wrapper}>
			<div className={s.info_wrapper}>
				<div className={s.info_header}>
					<h1>Как сделать заказ на пошив изделия? Все просто!</h1>
				</div>
				<div className={s.info_text}>
					<p>
						Выберите понравившуюся Вам модель сумки, размер (если нужно), цвет
						кожи, цвет деталей (если нужно), цвет фурнитуры.
					</p>
					<p>
						Нажмите кнопку «Оставить заявку». Далее, Вы будете перенаправлены в
						мессенджер WhatsApp. Там Вы всегда можете оставить комментарий к
						заказу или задать вопрос. Например, подобрать цвет кожи на Ваш вкус,
						изменить размер и тд.
					</p>
					<p>Уточнив все детали, менеджер подтверждает Ваш заказ.</p>
					<p>
						Услуга «Пошив на заказ» осуществляется по предоплате 50% от суммы
						заказа. Остальные 50 % оплачиваете по готовности.
					</p>
					<p>
						Срок пошива – до 14 дней, иногда срок может быть увеличен из-за
						загруженности.
					</p>
					<p>Доставка осуществляется после 100% оплаты товара.</p>
					<p>
						Действует бесплатная доставка по городу Иркутск и селу Хомутово.
						Доставка в другие города оплачивается по тарифу ТК CDEK. Стоимость
						доставки рассчитывается индивидуально после утверждения заказа.
					</p>
				</div>
			</div>
			<div className={s.main_wrapper}>
				<div className={s.castom_wrapper}>
					<div className={s.row_model}>
						<div className={s.model}>
							<div className={s.castom_title_model}>МОДЕЛЬ СУМКИ</div>
							<Swiper
								style={{
									"--swiper-navigation-color": "#000",
									"--swiper-navigation-size": "25px",
								}}
								className={s.color_second_wrapper}
								modules={[Navigation]}
								loop={true}
								spaceBetween={0}
								slidesPerView={5}
								navigation={true}
								onSlideChange={() => console.log("slide change")}
								onSwiper={(swiper) => console.log(swiper)}
								breakpoints={{
									// when window width is >= 640px
									1460: {
										width: 910,
										slidesPerView: 5,
									},
									1200: {
										width: 690,
										slidesPerView: 4,
									},
									1000: {
										width: 570,
										slidesPerView: 3,
									},
									300: {
										width: 350,
										slidesPerView: 2,
									},
								}}
							>
								{models}
							</Swiper>
						</div>
					</div>
					<div className={s.row_color}>
						<div className={s.castom_title}>ЦВЕТ КОЖИ</div>
						<Swiper
							style={{
								"--swiper-navigation-color": "#000",
								"--swiper-navigation-size": "25px",
							}}
							className={s.color_second_wrapper}
							modules={[Navigation]}
							loop={true}
							spaceBetween={0}
							slidesPerView={5}
							navigation={true}
							onSlideChange={() => console.log("slide change")}
							onSwiper={(swiper) => console.log(swiper)}
							breakpoints={{
								// when window width is >= 640px
								1450: {
									width: 965,
									slidesPerView: 7,
								},
								1200: {
									width: 700,
									slidesPerView: 5,
								},
								1000: {
									width: 692,
									slidesPerView: 5,
								},
								300: {
									width: 600,
									slidesPerView: 5,
								},
							}}
						>
							{colors}
						</Swiper>
					</div>
					<div className={s.row_colors_second} style={style_second_color}>
						<div className={s.castom_title}>ЦВЕТ ДЕТАЛЕЙ</div>
						<Swiper
							style={{
								"--swiper-navigation-color": "#000",
								"--swiper-navigation-size": "25px",
							}}
							className={s.color_second_wrapper}
							modules={[Navigation]}
							loop={true}
							spaceBetween={0}
							slidesPerView={5}
							navigation={true}
							onSlideChange={() => console.log("slide change")}
							onSwiper={(swiper) => console.log(swiper)}
							breakpoints={{
								// when window width is >= 640px
								1450: {
									width: 830,
									slidesPerView: 6,
								},
								1200: {
									width: 700,
									slidesPerView: 5,
								},
								1000: {
									width: 692,
									slidesPerView: 5,
								},
								300: {
									width: 600,
									slidesPerView: 5,
								},
							}}
						>
							{colors_second}
						</Swiper>
					</div>
					<div className={s.row_size} style={style_size}>
						<div className={s.castom_title}>РАЗМЕР</div>
						<div className={s.model_wrapper}>{size}</div>
					</div>
					<div className={s.row_furniture} style={style_furniture_block}>
						<div className={s.castom_title}>ФУРНИТУРА</div>
						<Swiper
							style={{
								"--swiper-navigation-color": "#000",
								"--swiper-navigation-size": "25px",
							}}
							className={s.model_wrapper}
							modules={[Navigation]}
							loop={true}
							spaceBetween={0}
							slidesPerView={5}
							navigation={true}
							onSlideChange={() => console.log("slide change")}
							onSwiper={(swiper) => console.log(swiper)}
							breakpoints={{
								// when window width is >= 640px
								550: {
									width: 450,
									slidesPerView: 3,
								},
								500: {
									width: 500,
									slidesPerView: 3,
								},
								430: {
									width: 300,
									slidesPerView: 2,
								},
								360: {
									width: 320,
									slidesPerView: 2,
								},
								200: {
									width: 280,
									slidesPerView: 2,
								},
							}}
						>
							{furniture}
						</Swiper>
					</div>
				</div>
				<div className={s.image_block}>
					<div className={s.image_block__image}>
						<img
							className={s.main_image}
							src={props.castom.final.main_image}
							alt=""
						/>
						<img
							className={s.detail_image}
							src={props.castom.final.detail_image}
							alt=""
						/>
					</div>
					<div className={s.form_info_main}>
						<h3 className={s.image_block_header}>ДЕТАЛИ ЗАКАЗА</h3>
						
						<div className={s.form_row} style={info_style}>
							<span className={s.info}>ПРИ ВЫБОРЕ ЦВЕТА ДАННОЙ МОДЕЛИ, УТОЧНЯЙТЕ ДЕТАЛИ У МЕНЕДЖЕРА ПРИ ОТПРАВКЕ ЗАЯВКИ</span>
						</div>
						<div className={s.form_row}>
							<span className={s.label}>МОДЕЛЬ:</span>
							<div className={s.input}>{props.castom.final.model_name}</div>
						</div>
						<div className={s.form_row}>
							<span className={s.label}>ОСНОВНОЙ ЦВЕТ:</span>
							<div className={s.input}>{props.castom.final.main_color}</div>
						</div>
						<div className={s.form_row} style={style_detail}>
							<span className={s.label}>ЦВЕТ ДЕТАЛЕЙ:</span>
							<div className={s.input}>{props.castom.final.detail_color}</div>
						</div>
						<div className={s.form_row} style={style_size_form}>
							<span className={s.label}>РАЗМЕР:</span>
							<div className={s.input}>{props.castom.final.size}</div>
						</div>
						<div className={s.form_row}>
							<span className={s.label}>ГАБАРИТЫ:</span>
							<div className={s.input}>{props.castom.final.dimension}</div>
						</div>
						<div className={s.form_row} style={style_furniture}>
							<span className={s.label}>ФУРНИТУРА:</span>
							<div className={s.input}>{props.castom.final.furniture}</div>
						</div>
						<div className={s.form_row}>
							<span className={s.label}>СТОИМОСТЬ:</span>
							<div className={s.input}>{props.castom.final.price}₽</div>
						</div>
						
					</div>
					{/*
          <button className={s.button_form} onClick={makeSale}>
            ОСТАВИТЬ ЗАЯВКУ
          </button>
          */}
					<a className={s.button_form} href={link} target="_blank">
						ОСТАВИТЬ ЗАЯВКУ
					</a>
				</div>
			</div>
			<form className={s.form_body} action="" style={style}>
				<h2 className={s.form_header}>ОСТАВИТЬ ЗАЯВКУ</h2>
				<div className={s.form_wrapper}>
					<div className={s.form_image_block}>
						<div className={s.form_image_block__image}>
							<img
								className={s.form_main_image}
								src={props.castom.final.main_image}
								alt=""
							/>
							<img
								className={s.form_detail_image}
								src={props.castom.final.detail_image}
								alt=""
							/>
						</div>
						<div className={s.form_info}>
							<h3 className={s.image_block_header}>ДЕТАЛИ ЗАКАЗА</h3>
							<div className={s.form_row}>
								<span className={s.label}>Модель:</span>
								<div className={s.input}>{props.castom.final.model_name}</div>
							</div>
							<div className={s.form_row}>
								<span className={s.label}>Основной цвет:</span>
								<div className={s.input}>{props.castom.final.main_color}</div>
							</div>
							<div className={s.form_row} style={style_detail}>
								<span className={s.label}>Цвет деталей:</span>
								<div className={s.input}>{props.castom.final.detail_color}</div>
							</div>
							<div className={s.form_row} style={style_size_form}>
								<span className={s.label}>Размер:</span>
								<div className={s.input}>{props.castom.final.size}</div>
							</div>
							<div className={s.form_row} style={style_furniture}>
								<span className={s.label}>Фурнитура:</span>
								<div className={s.input}>{props.castom.final.furniture}</div>
							</div>
						</div>
					</div>
					<div className={s.form_main}>
						<button
							type="button"
							className={s.form_close_button}
							onClick={checkForm}
						>
							<svg
								width="30px"
								height="30px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
									fill="#2e2e2e"
								/>
							</svg>
						</button>
						<div className={s.first_part_form}>
							<label className={s.form_label} htmlFor="">
								Ваше имя: <input type="text" />
							</label>
							<label className={s.form_label} htmlFor="">
								Номер телефона: <input type="text" />
							</label>
							<label className={s.form_label} htmlFor="">
								e-mail: <input type="text" />
							</label>
							<label className={s.form_label} htmlFor="">
								Комментарии: <textarea type="text" rows="7" />
							</label>
							<label className={s.checkbox} htmlFor="">
								<input type="checkbox" />{" "}
								<span>
									Соглашаюсь с политикой обработки персональных данных
								</span>
							</label>
							<input
								className={s.form_button}
								type="button"
								value="ОТПРАВИТЬ"
							/>
						</div>
					</div>
				</div>
			</form>
			<div className={s.back} style={style}></div>
		</div>
	);
};
export default Castom;
