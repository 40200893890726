import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import HeaderContainer from './components/Header/HeaderContainer';
import Main from './components/Main/Main';
import CatalogContainer from './components/Catalog/CatalogContainer';
import CardPageContainer from './components/Catalog/CardPage/CardPageContainer';
import CartPageContainer from './components/CartPage/CartPageContainer';
import CastomContainer from './components/Castom/CastomContainer';
import SertificatesContainer from './components/Sertificates/SertificatesContainer';
import SertificateCardContainer from './components/Sertificates/SertificateCard/SertificateCardContainer';
import AboutContainer from './components/About/AboutContainer';
import ScrollToTop from 'react-scroll-to-top';
import FooterContainer from './components/Footer/FooterContainer';
import AboutBrand from './components/AboutBrand/AboutBrand';
import NotFound from './components/NotFound/NotFound';

function App(props) {
  return (
    <BrowserRouter>
      <div className="app-wrapper">
        <HeaderContainer store={props.store} />
        <div className="app-wrapper-container">
          <Routes>
            <Route path="/*" element={<NotFound />} />
            <Route path="/" element={<Main store={props.store} />} />
            <Route path="/catalog" element={<CatalogContainer store={props.store} />} />
            <Route path="/catalog/:id" element={<CardPageContainer store={props.store} />} />
            <Route path="/cart" element={<CartPageContainer store={props.store} />} />
            <Route path="/castom" element={<CastomContainer store={props.store} />} />
            <Route path="/sertificates" element={<SertificatesContainer store={props.store} />} />
            <Route
              path="/sertificates/:id"
              element={<SertificateCardContainer store={props.store} />}
            />
            <Route path="/buyer" element={<AboutContainer store={props.store} />} />
            <Route path="/about" element={<AboutBrand store={props.store} />} />
          </Routes>
        </div>
        <ScrollToTop smooth />
        <FooterContainer store={props.store} />
      </div>
    </BrowserRouter>
  );
}

export default App;
