import React from "react";
import Castom from "./Castom";
import { changeColorAC, changeModelAC, changeColorDetailAC, changeFurnitureAC, checkFormAC, changeSizeAC } from "../../redux/castom-reducer";

const CastomContainer = (props) => {
	let state = props.store.getState();
	let changeColor = (index, index_detail) => {
		let action = changeColorAC(index, index_detail);
		props.store.dispatch(action);
	}
	let chengeModel = (model_name, index_model, details_block, size_state, dimension) => {
		let action = changeModelAC(model_name, index_model, details_block, size_state, dimension);
		props.store.dispatch(action);
	}
	let changeColorDetail = (index) => {
		let action = changeColorDetailAC(index);
		props.store.dispatch(action);
	}
	let changeFurniture = (index, name_furniture) => {
		let action = changeFurnitureAC(index, name_furniture);
		props.store.dispatch(action);
	}
	let changeSize = (index, name_size) => {
		let action = changeSizeAC(index, name_size);
		props.store.dispatch(action);
	}
	let checkForm = (new_check) => {
		let action = checkFormAC(new_check);
		props.store.dispatch(action);
	}
	return (
		<Castom castom={state.castom}
			changeColor={changeColor}
			chengeModel={chengeModel}
			changeColorDetail={changeColorDetail}
			changeFurniture={changeFurniture}
			checkForm={checkForm} 
			changeSize={changeSize}/>
	)
}

export default CastomContainer;