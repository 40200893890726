import React from "react";
import s from "./CardDescription.module.css"

const CardDescription = (props) => {
	let style;
	props.text.split(' ', 1) == "Внимание!" ?  style = {fontWeight: "500", fontSize: "20px"} : style = {fontWeight: "100"} ;
	return (
		<div className={s.description_wrapper}>
			<div>
				<div style={style}>{props.text}</div>
				<div>{props.sub_description}</div>
			</div>
			<div>
				<div>{props.accessories}</div>
				<div>{props.lining}</div>
				<div>{props.material}</div>
			</div>
			<div>{props.size}</div>
			<div>{props.weight}</div>
		</div>
	)
}

export default CardDescription;